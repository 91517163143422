/* PARALLAX
- add parallax by adding css class parallax to desired element
- also reference one of prior parallax sites to get data speed variable applied via html to control output speed of function */

if (Modernizr.touch) {   
	
} else {   
	$(document).ready(function(){
		// Cache the Window object
		$window = $(window);

	   $(".parallax").each(function(){
		 var $bgobj = $(this); // assigning the object
					
		  $(window).scroll(function() {
			var yPos = $window.scrollTop() / $bgobj.data('speed'); 
			var coords = 'center '+ (yPos - 100) + 'px';
			$bgobj.css({ backgroundPosition: coords });
			});
		});

	});
}

$(document).ready(function() {
  function setSize() {
    windowHeight = $(window).innerHeight();
    windowWidth = $(window).innerWidth();
    $('#big-hero').css('min-height', windowHeight);
  };
  setSize();
  
  $(window).resize(function() {
    setSize();
  });
});


var fadeStart=100,fadeUntil=300,fading = $('.fadeOnScroll');

$(window).bind('scroll', function(){
    var offset = $(document).scrollTop(),opacity=0;
    if( offset<=fadeStart ){
        opacity=1;
    } else if ( offset<=fadeUntil ){
        opacity=1-offset/fadeUntil;
    }
    fading.css('opacity',opacity);
});


/* PRELOAD
- add preload hack to body element via base.php */

$(window).load(function() {
  $("body").removeClass("preload");
});


/* NAV SCROLL DOWN CLASS LOADER
- add nav functionality on scroll down */

$(document).ready(function(){
  $(window).bind('scroll', function() {
    var navHeight = 300; // custom nav height
    ($(window).scrollTop() > navHeight) ? $('nav').addClass('nav-scrolled') : $('nav').removeClass('nav-scrolled');
  });
});

 /* ICON AREA SCROLL DOWN
 - forces users to top of hidden content wrapper when clicking class icon */
 
$('.fadeOnScroll').click(function() { 
	if ($(window).height() > 500) {
		$('html, body').animate({ scrollTop: $('header').offset().top }, 2000);
	}
});