import './bootstrap/affix';
import './bootstrap/alert';
import './bootstrap/button';
import './bootstrap/carousel';
import './bootstrap/collapse';
import './bootstrap/dropdown';
import './bootstrap/modal';
import './bootstrap/tooltip';
import './bootstrap/popover';
import './bootstrap/scrollspy';
import './bootstrap/tab';
import './bootstrap/transition';
